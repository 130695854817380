/* eslint-disable */
import { useState, useEffect, useRef, setState } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import MainTitle from '../../components/MainTitle'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import ClientNav from '../../components/ClientNav'
import ActiveQuestionnaire from '../../components/ActiveQuestionnaire'

import Modal from '../../components/Modal'
import TriageDetails from '../../components/TriageDetails'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'

const Session = () => {
  const { clientId, claimId, sessionId } = useParams()
  const axios = useAxiosPrivate()
  const [clientData, setClientData] = useState({
    name: '',
    reference: '',
  })
  const [claimData, setClaimData] = useState({
    reference: '',
    external_reference: '',
  })
  const [sessionData, setSessionData] = useState({ notes: '' })
  const { setShowLoader, auth, createInfo, admin, can } = useAuth()

  const [assignRisk, setAssignRisk] = useState({})
  const [riskOptions, setRiskOptions] = useState([])
  const [riskReasons, setRiskReasons] = useState([])
  const [currentRisk, setCurrentRisk] = useState({})
  const [sessionToCancel, setSessionToCancel] = useState(false)
  const [sessionToComplete, setSessionToComplete] = useState(false)
  const [requestToCancel, setRequestToCancel] = useState(false)
  const [requestReason, setRequestReason] = useState({})
  const [cancelReasons, setCancelReasons] = useState([])
  const [selectedCancelReason, setSelectedCancelReason] = useState('0')
  const [completionReasons, setCompletionReasons] = useState([])
  const [selectedCompletionReason, setSelectedCompletionReason] = useState('0')
  const [newQuestionnaire, setNewQuestionnaire] = useState(false)

  const [deleteQResponse, setDeleteQResponse] = useState({})

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [clinicId, setClinicId] = useState('')
  const [questionnaires, setQuestionnaires] = useState([])
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(0)
  const [sessionQuestionnaires, setSessionQuestionnaires] = useState({})
  const [activeQuestionnaire, setActiveQuestionnaire] = useState({})
  const [updateQuestionnaire, setUpdateQuestionnaire] = useState(false)
  const [showQuestionnaire, setShowQuestionnaire] = useState(false)
  const [downloadable, setShowDownloadButton] = useState(true)
  const [showDischargeModal, setShowDischargeModal] = useState(false)
  const [remainingSessions, setRemainingSessions] = useState(0)
  const [usedSession, setUsedSessions] = useState([])

  const navigate = useNavigate()

  const getSessionById = async () => {
    setShowLoader(true)
    try {
      const response = await axios.get(
        `/clients/${clientId}/claims/${claimId}/sessions/${sessionId}`
      )
      setClientData(
        response.data.result.claim_relationship[0].client_relationship
      )
      setCurrentRisk(
        response.data.result.claim_relationship[0].client_relationship.risk[0]
      )
      setSessionQuestionnaires(response.data.result.questionnaire_responses)
      setClaimData(response.data.result.claim_relationship[0])
      setSessionData(response.data.result)
      setClinicId(response.data.result.claim_relationship[0].company_id)
      getRemainingSessions(response.data.result.claim_relationship[0])
      setUsedSessions(response.data.result.claim_relationship[0].used_sessions)
    } catch (error) {}
    setShowLoader(false)
  }

  const getRiskOptions = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/risk-options/all`)
      if (response.data.result) {
        await setRiskOptions(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getRiskReasons = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/risk-reasons/all`)
      if (response.data.result) {
        await setRiskReasons(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getCancelReasons = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/cancel-reasons/all`)
      if (response.data.result) {
        await setCancelReasons(response.data.result)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const getCompletionReasons = async () => {
    try {
      setShowLoader(true)
      const response = await axios.get(`/completion-reasons/all`)
      if (response.data.result) {
        await setCompletionReasons(response.data.result.reasons)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const checkIsDischargable = () => {
    if (!usedSession || usedSession.length === 0) {
      return false;
    }
  
    const totalSessions = usedSession.length;
    const latestSession = usedSession[totalSessions - 1];
    const latestSessionDate = new Date(latestSession.start_time);
    const today = new Date();
    
    if ((latestSession.id == sessionId) && (latestSessionDate >= today) && (remainingSessions < 1)) {
      return true;
    }
  
    return false;
  }

  const getRemainingSessions = (claimData) => {
    let remaining = claimData.session_count - claimData.used_sessions.length
    setRemainingSessions(remaining)
  }

  useEffect(() => {
    getSessionById()
    getRiskOptions()
    getRiskReasons()
    getCancelReasons()
    getCompletionReasons()
  }, [])

  const getQuestionnaires = async () => {
    try {
      const response = await axios.get(`companies/${clinicId}/questionnaires`)
      if (response.data.result) {
        await setQuestionnaires(response.data.result)
      }
    } catch (error) {}
  }

  const updateRiskLevelClicked = (risk) => {
    setAssignRisk({})
    setModalTitle(`Update Client Risk Level`)
    setShowModal(true)
  }

  const deleteQResponseClicked = (qResponse) => {
    setDeleteQResponse({ ...qResponse })
    setModalTitle(
      `Delete Questionnaire Response: ${JSON.parse(qResponse.content).title}`
    )
    setShowModal(true)
  }

  const dischargeClientClicked = () => {
    setShowDischargeModal(true);
    setModalTitle(`Would you like to discharge: ${claimData.reference}`)
    setShowModal(true)
  }

  const addQuestionnaireClicked = async () => {
    await getQuestionnaires()
    setNewQuestionnaire(true)
    setModalTitle(`Add a Questionnaire`)
    setShowModal(true)
  }

  const cancelSessionClicked = () => {
    setModalTitle(`Cancel Session`)
    setSessionToCancel(true)
    setShowModal(true)
  }

  const completeSessionClicked = () => {
    setModalTitle(`Complete Session`)
    setSessionToComplete(true)
    setShowModal(true)
  }

  // leaving in incase practioners abuse privalidge & we need to revert to requesting
  const requestCancelSessionClicked = () => {
    setModalTitle(`Request to cancel this session?`)
    setRequestToCancel(true)
    setShowModal(true)
  }

  const CompleteSessionElement = () => {
    const completeSession = async (e) => {
      e.preventDefault()
      try {
        const completionReason = JSON.parse(selectedCompletionReason);
        const response = await axios.post(`/sessions/complete/${sessionId}`, {
          confirmation_reason_id: completionReason.id,
        });
        if (!response.data.error) {
          createInfo('success', `Session has been updated`)
          setSessionData({
            ...sessionData,
            completed: 1,
            completionReason: completionReason,
          })
        }
      } catch (error) {}
    }

    return (
      <form onSubmit={completeSession}>
        <div className="flex justify-center mb-4 p-2">
          <h3 className="text-lg font-bold">
            Are you sure you want to complete this session?
          </h3>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Please select a reason for completing this session
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              defaultValue={selectedCompletionReason || "0"}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              id="confiromation-reason"
              onChange={(e) => setSelectedCompletionReason(e.target.value)}
            >
              <option disabled value="0">
                Select a completion reason
              </option>
              {completionReasons?.map((reason) => {
                return (
                  <option key={reason.id} value={JSON.stringify(reason)}>
                    {reason.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button
            type="submit"
            className={` mt-4 mr-4 w-1/2 ${(!selectedCompletionReason || selectedCompletionReason === "0") ? 'cursor-not-allowed text-sm inline-flex justify-center items-center bg-gray-500 border-gray-500 text-white font-bold py-2.5 px-4 border rounded' : 'btn blue'}`}
            disabled={!selectedCompletionReason || selectedCompletionReason === "0"}>
            Yes, confirm this session
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            No
          </button>
        </div>
      </form>
    )
  }

  const CancelSessionElement = () => {
    const cancelSession = async (e) => {
      e.preventDefault()
      let chosenReason = JSON.parse(selectedCancelReason);
      try {
        updateAdditionalInfo(chosenReason)
        setSessionData({
          ...sessionData,
          cancelled: 1,
          cancel_reason: {
            title: reason.title
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    return (
      <form onSubmit={cancelSession}>
        <div className="flex justify-center mb-4 p-2">
          <h3 className="text-lg font-bold">
            Are you sure you want to cancel this session?
          </h3>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Please select a reason for cancelling this session
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              defaultValue={selectedCancelReason || "0"}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              id="cancel-reason"
              onChange={(e) => setSelectedCancelReason(e.target.value)}
            >
              <option disabled value="0">
                Select a cancel reason
              </option>
              {cancelReasons?.map((reason) => {
                return (
                  <option key={reason.id} value={JSON.stringify(reason)}>
                    {reason.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        {selectedCancelReason && (selectedCancelReason !== '0' && selectedCancelReason !== 0) ? (
          <div className="flex content-center my-4">
            <span className="content-center text-red-600">
              {(JSON.parse(selectedCancelReason).id === 1 || JSON.parse(selectedCancelReason).id === 2)
                ? 'Make sure you let the client know, they will not be able to get this session back.'
                : 'This session will not be taken from the client’s allowance.'}
            </span>
          </div>
        ) : (
          <div></div>
        )}
        <div className="flex mt-2 w-full">
          <button
            type="submit"
            className={` mt-4 mr-4 w-1/2 ${(!selectedCancelReason || selectedCancelReason === "0") ? 'cursor-not-allowed text-sm inline-flex justify-center items-center bg-gray-500 border-gray-500 text-white font-bold py-2.5 px-4 border rounded' : 'btn blue'}`}
            disabled={!selectedCancelReason || selectedCancelReason === "0"}>
            Yes, cancel this session
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            No
          </button>
        </div>
      </form>
    )
  }

  const RequestCancellationElement = () => {
    const cancelSession = async (e) => {
      e.preventDefault()
      try {
        const response = await axios.post(
          '/notifications/create',
          {
            title: 'Request Cancellation',
            body: selectedCancelReason,
            meta: {
              client_id: clientData.id,
              claim_id: claimData.id,
              client_reference: clientData.reference,
              claim_reference: claimData.reference,
              session_id: sessionData.id,
              session_reference: sessionData.reference,
              session_date: sessionData.start_time,
            },
            type: 'Admin',
            area: 'Client'
          }
        )
        if (!response.data.error) {
          createInfo(
            'success',
            `Request for cancellation has been submitted`
          )
          setCurrentRisk(response.data.result)
          resetModal()
        }
      } catch (error) {
        createInfo(
          'error',
          `${error}`
        )
      }
    }

    return (
      <form onSubmit={cancelSession}>
        <div className="flex justify-center mb-4 p-2">
          <h3 className="text-lg font-bold">
            Are you sure you want to cancel this session?
          </h3>
        </div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Please select a reason for cancelling this session
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              defaultValue={selectedCancelReason}
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              id="cancel-reason"
              onChange={(e) => setSelectedCancelReason(e.target.value)}
            >
              <option disabled value="0">
                Select a cancel reason
              </option>
              {cancelReasons?.map((reason) => {
                return (
                  <option key={reason.id} value={reason.title}>
                    {reason.title}
                  </option>
                )
              })}
            </select>
          </div>
          {selectedCancelReason && (selectedCancelReason !== '0' && selectedCancelReason !== 0) ? (
            <div className="flex content-center my-4">
              <span className="content-center text-red-600">
                {(JSON.parse(selectedCancelReason).id === 1 || JSON.parse(selectedCancelReason).id === 2)
                  ? 'Make sure you let the client know, they will not be able to get this session back.'
                  : 'This session will not be taken from the client’s allowance.'}
              </span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn blue mt-4 mr-4 w-1/2">
            Yes, request cancellation
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            No
          </button>
        </div>
      </form>
    )
  }

  const UpdateRiskAssignElement = () => {
    const addRisk = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      try {
        const response = await axios.post(
          `/clients/${clientData.id}/risk/add`,
          {
            risk_id: assignRisk.id,
            risk_reason_id: assignRisk.reason,
            session_id: sessionId,
          }
        )
        if (!response.data.error) {
          createInfo(
            'success',
            `Updated Client Risk Level to ${response.data.result.title}`
          )
          setCurrentRisk(response.data.result)
          resetModal()
        }
      } catch (error) {
        console.log(error)
      }
      setShowLoader(false)
    }

    return (
      <form onSubmit={addRisk}>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Risk Level
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => {
                assignRisk.id = e.target.value
              }}
              id="risk"
            >
              <option disabled value="0">
                Select a Risk Level
              </option>
              {riskOptions?.map((option) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Risk Reason
          </label>
          <div className="mt-1 relative rounded-md">
            <select
              className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
              required
              onChange={(e) => {
                assignRisk.reason = e.target.value
              }}
              id="riskReason"
            >
              <option disabled value="0">
                Select a Risk Reason
              </option>
              {riskReasons?.map((reason) => {
                return (
                  <option key={reason.id} value={reason.id}>
                    {reason.title}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="flex mt-2 w-full">
          <button className="btn blue mt-4 mr-4 w-1/2">
            Update Risk Level
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </form>
    )
  }

  const AddQuestionnaireElement = () => {
    const goToQuestionnaire = async () => {

      await setActiveQuestionnaire(JSON.parse(selectedQuestionnaire))
      setShowQuestionnaire(true)
      setUpdateQuestionnaire(false)
      setShowDownloadButton(false)
      resetModal()
    }

    return (
      <div>
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block font-medium text-gray-600 text-sm"
          >
            Select a Questionnaire
          </label>
          <select
            defaultValue={selectedQuestionnaire ?? 0}
            onChange={(e) => setSelectedQuestionnaire(e.target.value)}
            className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
          >
            <option value={0} disabled>
              Select a Questionnaire
            </option>
            {questionnaires?.map((item, index) => {
              return (
                <option key={item.id} value={JSON.stringify(item)}>
                  {item.title}
                </option>
              )
            })}
          </select>
        </div>

        <div className="flex mt-2 w-full">
          <button
            className="btn blue mt-4 mr-4 w-1/2"
            onClick={goToQuestionnaire}
          >
            Add Questionnaire
          </button>

          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={resetModal}
          >
            Cancel
          </button>
        </div>
      </div>
    )
  }

  const DeleteQuestionnaireResponseElement = () => {
    const deleteQuestionnaireResponse = async (e) => {
      e.preventDefault()
      setShowLoader(true)
      resetModal(false)
      try {
        const response = await axios.get(
          '/questionnaire-response/delete/' + deleteQResponse.id
        )
        createInfo(
          'error',
          `Deleted Questionnaire Response: ${deleteQResponse.title}`
        )
        setSessionQuestionnaires(response.data.result)
        setShowLoader(false)
      } catch (error) {
        setShowLoader(false)
      }
    }

    return (
      <form onSubmit={deleteQuestionnaireResponse}>
        <div className="mb-3">
          <p className="text-lg font-bold my-8 text-center">
            Are you sure you want to delete this Questionnaire Response?
          </p>
          <div className="flex mt-2 w-full">
            <button className="btn red mt-4 mr-4 w-1/2">Delete</button>

            <button
              type="button"
              className="btn mt-4 w-1/2"
              onClick={resetModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    )
  }

  const DischargeReasonElement = () => {
    const addDischargeClient = async (e) => {
      e.preventDefault();
      const reason = e.target.elements.reason.value;
      if (!reason) {
        createInfo('error', 'Please provide a reason');
        return;
      }
      setShowLoader(true);
      resetModal(false);
      try {
          const response = await axios.post(
            '/notifications/create',
            {
              title: 'Request to Discharge',
              body: reason,
              meta: {
                client_id: claimData.client_id,
                client_reference: claimData.client_relationship.reference,
                claim_id: claimData.id,
                claim_reference: claimData.reference,
              },
              type: 'Admin',
              area: 'Claim',
            }
          )
          updateSession();
          setShowLoader(false)        
      } catch (error) {
        setShowLoader(false);
      }
    };
  
    return (
      <form onSubmit={addDischargeClient}>
        <div className="mb-3">
          <label
            htmlFor="reason"
            className="block font-medium text-gray-600 text-sm"
          >
            Reason <span className="text-red-500">*</span>
          </label>
          <div className="mt-1 relative rounded-md">
            <textarea
              required
              type="text"
              name="reason"
              id="reason"
              defaultValue="Final Session"
              className="px-4 py-2 block w-full border border-gray-300 rounded-md shadow-md"
            />
          </div>
        </div>
        <div className="flex mt-2 w-full">
          <button className="btn mt-4 mr-4 w-1/2">Yes</button>
          <button
            type="button"
            className="btn red mt-4 w-1/2"
            onClick={() => {
              resetModal();
              updateSession();
            }}>
            No
          </button>
        </div>
      </form>
    );
  };

  const ModalBody = () => {
    if (showDischargeModal) {
      return <DischargeReasonElement />
    }
    if (sessionToCancel) {
      return <CancelSessionElement />
    }
    if (sessionToComplete) {
      return <CompleteSessionElement />
    }
    if (requestToCancel) {
      return <RequestCancellationElement />
    }
    if (deleteQResponse.id) {
      return <DeleteQuestionnaireResponseElement />
    }
    if (newQuestionnaire) {
      return <AddQuestionnaireElement />
    }
    if (assignRisk) {
      return <UpdateRiskAssignElement />
    }
  }

  const resetModal = () => {
    setShowModal(false)
    setSelectedQuestionnaire(0)
    setNewQuestionnaire(false)
    setAssignRisk({})
    setSessionToCancel(false)
    setSessionToComplete(false)
    setRequestToCancel(false)
    setRequestReason({})
    setSelectedCancelReason(0)
    setDeleteQResponse({})
    setShowDischargeModal({})
    setShowDischargeModal(false)
  }

  const updateSession = async () => {
    setShowLoader(true)

    const data = {
      client_attended: sessionData.client_attended,
      notes: sessionData.notes,
    }

    try {
      const response = await axios.post(`/sessions/update/${sessionId}`, data)
      if (!response.data.error) {
        createInfo('success', `Session has been updated`)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  const goToClaim = () => {
    navigate(`/clients/${clientId}/claims/${claimId}`)
  }

  const updateAdditionalInfo = async (reason) => {
    setShowLoader(true)
    let data = {}
    data['cancel_reason_id'] = reason.id
    try {
      const response = await axios.post(`/sessions/cancel/${sessionId}`, data)
      if (!response.data.error) {
        setSessionData(prevSessionData => ({
          ...prevSessionData,
          cancelled: 1,
          cancel_reason: {
            title: response.data.result.session.cancel_reason.title
          }
        }));
        setShowLoader(false)
        resetModal()
        createInfo('success', `Session has been updated`)
      }
    } catch (error) {}
    setShowLoader(false)
  }

  return (
    <div>
      <MainTitle
        title={`Client: ${clientData.name} (${clientData.reference})`}
        client={clientData}
        backButton
        child={clientData.child}
        discharge
      />

      {showModal && (
        <Modal title={modalTitle} body={<ModalBody />} show={resetModal} />
      )}

      <ClientNav active="claims" client={clientData} audit={sessionData.id} type={'Session'} />

      {!showQuestionnaire && sessionData.id && (
        <section>
          <span
            className="inline-flex items-center text-slate-500 mb-2 cursor-pointer hover:text-slate-600"
            onClick={goToClaim}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
            <span className="text-sm ml-2">View Claim</span>
          </span>
          <section className="w-full bg-white rounded-xl shadow-md py-4 px-4 mb-8">
            <div className="flex justify-between">
              <h2 className={sessionData?.video_session !== 1 ? 'font-bold text-lg mb-4' : 'font-bold text-lg'}>
                {claimData.reference} ({claimData.external_reference}) -{' '}
                {claimData.service.title}
              </h2>
              <button className="btn primary" onClick={() => {
                    if (checkIsDischargable()) {
                      dischargeClientClicked();
                    } else {
                      updateSession(); 
                    }
                  }}>
                Update Session
              </button>
            </div>

            {sessionData?.video_session === 1 && (
              <div className='flex mb-4 text-blue-500 font-semibold'>
                <svg xmlns="http://www.w3.org/2000/svg" width="15px" fill="#3b82f6" className="mr-2" viewBox="0 0 576 512"><path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" /></svg>
                <h3>This is a video Session</h3>
              </div>
            )}

            <div>
              {can('access triage options') && (
                <>
                  <TriageDetails
                    claimData={claimData}
                    clientData={clientData}
                    referrableServices={[]}
                  />
                </>
              )}
            </div>
            <div className="mb-4">
              <div className="block mb-2">
                <h3>Questionnaires</h3>
                <div className="flex items-center">
                  <div className="flex items-center justify-between rounded-l-sm border border-r-0 w-full border-gray-200 p-2 h-12">
                    <div className=" relative rounded-md">
                      <ul className="flex">
                        {sessionQuestionnaires?.map((questionnaire) => {
                          console.log(questionnaire)
                          return (
                            <li key={questionnaire.id} className="flex">
                              <button
                                className="pill-qr btn blue"
                                onClick={() => {
                                  setActiveQuestionnaire(questionnaire)
                                  setShowQuestionnaire(true)
                                  setUpdateQuestionnaire(true)
                                  setShowDownloadButton(true)
                                }}
                              >
                                {questionnaire.content?.title}
                              </button>
                              {/* {can('remove user services') ? ( */}
                              <span
                                title="Remove Questionnaire"
                                className="pill-button-qr"
                                onClick={() =>
                                  deleteQResponseClicked(questionnaire)
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                              {/* ) : ( */}
                              {/* <span className="mr-4"></span> */}
                              {/* )} */}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="flex justify-end items-center rounded-r-lg border border-gray-200 bg-gray-300 h-12 px-4 cursor-pointer text-sm"
                    onClick={addQuestionnaireClicked}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label
                htmlFor="session_notes"
                className="text-sm font-medium text-gray-900"
              >
                Session Notes
              </label>
              <textarea
                required
                className="w-full border rounded-sm py-3 px-3 text-sm"
                defaultValue={sessionData.notes}
                onChange={(e) => {
                  sessionData.notes = e.target.value
                }}
                placeholder=""
              ></textarea>
            </div>

            <div className="items-center flex mb-8">
              <input
                type="checkbox"
                id="client_attended"
                defaultChecked={!sessionData.client_attended}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                onChange={(e) =>
                  (sessionData.client_attended = !e.target.checked)
                }
              />
              <label
                htmlFor="client_attended"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Client did not attend the session.
              </label>
            </div>

            <div className="mb-8">
              <div className="block mb-2">
                <span className="text-wba-primary font-semibold mr-2">
                  Current Risk Level:
                </span>
                <span
                  className="status"
                  style={{ backgroundColor: currentRisk.color }}
                >
                  {currentRisk.title || 'N/A'}
                </span>
              </div>
              <button className="btn warning" onClick={updateRiskLevelClicked}>
                Update Client Risk Level
              </button>
            </div>

            <hr className="bg-gray-300 w-full my-4" />
            <div>
              <h3 className="font-bold mb-2">Additional Information:</h3>
              {sessionData.cancelled == 1 && (
                <>
                  <span className="bg-orange-500 text-white text-sm py-2 px-4 rounded mb-2 inline-flex">
                    This session has been cancelled, this cannot be changed
                  </span>
                  <span className="flex font-bold">
                    Cancel Reason: {sessionData.cancel_reason?.title}
                  </span>
                </>
              )}
            
              <ul className="space-x-4 flex items-center">
              {sessionData.status !== 'Completed' && (
                <li className="flex flex-col mb-2">
                  <div className="toggle flex items-center">
                    <button
                      className="btn warning"
                      onClick={(e) => completeSessionClicked()}
                    >
                      Complete Session
                    </button>
                  </div>
                </li>
              )}
              {sessionData.confirmation_reason_id !== null && (
                <li className="flex flex-col mb-2">
                  <div className="toggle flex items-center">
                    <button
                      className="btn warning"
                    >
                      This session has been marked as {sessionData.confirmation_reason}
                    </button>
                  </div>
                </li>
              )}
              {sessionData.confirmation_reason_id === null && sessionData.notified === 1 && (
                <li className="flex flex-col mb-2">
                  <div className="toggle flex items-center">
                    <button
                      className="btn warning"
                    >
                      The AC has been notified to complete this session
                    </button>
                  </div>
                </li>
              )}
              {!sessionData.cancelled && (
                <li className="flex flex-col mb-2">
                  {can('cancel session') ? (
                    <div className="toggle flex items-center">
                      <button
                        className="btn red"
                        onClick={(e) => cancelSessionClicked()}
                      >
                        Cancel Session
                      </button>
                    </div>
                  ) : (
                    <div className="toggle flex items-center">
                      <button className="btn red"
                      onClick={(e) => requestCancelSessionClicked()}>Request Cancellation</button>
                    </div>
                  )}
                </li>
              )}
                
                {/* <li className="flex flex-col mb-2">
                  <span className="font-medium">Cancel Reason?</span>
                  <div>
                    {admin ? (
                      <div className="mt-1 relative rounded-md w-1/4">
                        <select
                          defaultValue={sessionData.cancel_reason_id ?? 0}
                          className="px-2 py-2 block w-full border border-gray-300 rounded-md shadow-md"
                          required
                          disabled={
                            sessionData.cancel_reason_id &&
                            sessionData.cancel_reason_id > 0
                          }
                          onChange={(e) => {
                            updateAdditionalInfo(
                              'cancel_reason_id',
                              e.target.value
                            )
                            setSessionData({
                              ...sessionData,
                              cancel_reason_id: e.target.value,
                            })
                          }}
                          id="cancel-reason"
                        >
                          <option disabled value={0}>
                            Select a cancel reason
                          </option>
                          {cancelReasons?.map((reason) => {
                            return (
                              <option key={reason.id} value={reason.id}>
                                {reason.title}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    ) : (
                      <span>
                        {sessionData.cancel_reason
                          ? sessionData.cancel_reason.title
                          : 'N/A'}
                      </span>
                    )}
                  </div>
                </li> */}
                {/* <li className="flex flex-col">
                  <span className="font-medium">Session Refunded?</span>

                  {admin ? (
                    <div className="toggle flex items-center">
                      <span className="text-sm font-bold mr-3">No</span>
                      <label className="flex items-center relative w-max cursor-pointer select-none">
                        <input
                          defaultChecked={sessionData.refunded}
                          onChange={(e) =>
                            updateAdditionalInfo('refunded', e.target.checked)
                          }
                          type="checkbox"
                          className="appearance-none transition-colors cursor-pointer w-[60px] h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue focus:ring-blue-500 bg-gray-300 shadow-inner "
                        />
                        <span className="w-6 h-6 right-8 absolute rounded-full transform transition-transform bg-white" />
                      </label>
                      <span className="text-sm font-bold ml-3">Yes</span>
                    </div>
                  ) : (
                    <span>{sessionData.refunded !== 1 ? 'No' : 'Yes'}</span>
                  )}
                </li> */}
              </ul>
            </div>
          </section>
        </section>
      )}

      {showQuestionnaire && (
        <ActiveQuestionnaire
          questionnaireData={activeQuestionnaire}
          resetQ={() => {
            setShowQuestionnaire(false)
            setActiveQuestionnaire({})
          }}
          session={sessionData}
          clientReference={clientData.reference}
          setList={setSessionQuestionnaires}
          list={sessionQuestionnaires}
          update={updateQuestionnaire}
          downloadable={downloadable}
        />
      )}
    </div>
  )
}

export default Session
